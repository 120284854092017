// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"zo1N9xu3oddg3y5zmE9yp"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.NEXT_PUBLIC_SENTRY_DNS
) {
  /** No need to log in sentry in development */
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
  });
}
